/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/index.css"
import AOS from "aos"
import Modal from "react-modal"
import "aos/dist/aos.css"
import Cookies from "js-cookie"

export const onClientEntry = () => {
  const disableGACookieName = `ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`

  window[disableGACookieName] = Cookies.get(disableGACookieName)
    ? Cookies.get(disableGACookieName) === "true"
    : true
}

export const onInitialClientRender = () => {
  AOS.init()
  Modal.setAppElement("#gatsby-focus-wrapper")
}
